// projectargus colors

// primary

$light-primary: #ee8c56;
$light-primary-contrast: #444444;

$dark-primary: #ee8c56;
$dark-primary-contrast: #444444;

// accent/secondary

$light-accent: #1e5255;
$light-accent-contrast: #ffffff;

$dark-accent: #1e5255;
$dark-accent-contrast: #ffffff;

// common

$page-background: #ee8c562f;
